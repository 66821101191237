import type { MenuProps } from "antd"
import { FC } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { SvgIcon } from "../../shared/icons"
import { Dropdown } from "../ui"
import Navigation from "./Navigation"
import "./index.less"

type MenuItem = Required<MenuProps>["items"][number]

const menuItems: MenuProps["items"] = [
  {
    label: <a href='#'>1st menu item</a>,
    key: "0",
  },
  {
    label: <a href='#'>2nd menu item</a>,
    key: "1",
  },
]

const items: MenuItem[] = [
  {
    key: "menu",
    label: <SvgIcon type={"burgerMenu"} style={{ cursor: "pointer" }} />,
  },
  {
    key: "home",
    label: (
      <NavLink className={"home"} to={"/"}>
        Home
      </NavLink>
    ),
  },
  {
    key: "work",
    label: <Dropdown menu={{ items: menuItems }}>My work</Dropdown>,
  },
  {
    key: "identities",
    label: <Dropdown menu={{ items: menuItems }}>Identities</Dropdown>,
  },
  {
    key: "applications",
    label: <Dropdown menu={{ items: menuItems }}>Applications</Dropdown>,
  },
  {
    key: "intelligence",
    label: <Dropdown menu={{ items: menuItems }}>Intelligence</Dropdown>,
  },
  {
    key: "setup",
    label: <Dropdown menu={{ items: menuItems }}>Setup</Dropdown>,
  },
]

const HeaderMenu: FC = () => {
  const { pathname } = useLocation()
  return <Navigation mode={"horizontal"} activeKey={pathname} items={items} />
}
export { HeaderMenu }
