import { Dropdown as AntDropdown, DropdownProps, Row } from "antd"
import { FC, PropsWithChildren, useState } from "react"
import { SvgIcon } from "src/shared/icons"

interface CustomDropdownProps extends DropdownProps {
  customClassName?: string
}

export const Dropdown: FC<PropsWithChildren<CustomDropdownProps>> = ({ customClassName, ...props }) => {
  const [menuVisible, setMenuVisible] = useState(false)

  const handleVisibleChange = (visible: boolean) => {
    setMenuVisible(visible)
  }
  return (
    <AntDropdown
      trigger={["click"]}
      rootClassName={"custom-dropdown"}
      open={menuVisible}
      onOpenChange={handleVisibleChange}
      {...props}
    >
      <Row align={"middle"} style={{ gap: 2, cursor: "pointer" }} wrap={false} onClick={e => e.preventDefault()}>
        {props.children}
        <SvgIcon
          type={"caretDown"}
          style={{
            cursor: "pointer",
            transform: menuVisible ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s",
          }}
        />
      </Row>
    </AntDropdown>
  )
}
