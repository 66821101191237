import type { MenuProps } from "antd"
import { Avatar, Flex, Input, Row, Typography } from "antd"
import { FC } from "react"
import { Dropdown } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import "./FilterHeader.less"

const items: MenuProps["items"] = [
  {
    label: <a href='#'>1st menu item</a>,
    key: "0",
  },
  {
    label: <a href='#'>2nd menu item</a>,
    key: "1",
  },
]

const FilterHeader: FC = () => {
  return (
    <Row className={"filter-header-container"} align={"middle"} justify={"space-between"} wrap={false}>
      <Flex gap={14} align={"center"}>
        <Avatar shape='square' size='large' icon={<SvgIcon type={"amazon"} />} />
        <Flex vertical>
          <Dropdown menu={{ items }}>
            <Typography.Title level={3} className={"company-name"}>
              Amazon
            </Typography.Title>
          </Dropdown>
          <Typography.Paragraph className={"company-full-name"}>Amazon.com, Inc.</Typography.Paragraph>
        </Flex>
      </Flex>
      <Input.Search
        size={"large"}
        placeholder={"Search people by name or job title..."}
        prefix={<SvgIcon type={"search"} />}
        enterButton={null}
        style={{ maxWidth: "480px" }}
      />
      <Row className={"side-tools"} align={"middle"}>
        <div className={"active-tool"}>
          <SvgIcon type={"stackTool"} style={{ cursor: "pointer" }} />
        </div>
        <SvgIcon type={"rowsTool"} style={{ cursor: "pointer" }} />
      </Row>
    </Row>
  )
}

export { FilterHeader }
