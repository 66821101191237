import { ThemeConfig } from "antd/es/config-provider"

const theme: ThemeConfig = {
  inherit: false,
  token: {
    colorText: "#212840",
    fontFamily: "'Avenir', sans-serif",
  },
  components: {
    Menu: {
      padding: 0,
      colorBorderBg: "transparent",
      colorPrimaryBg: "unset",
      darkItemSelectedBg: "transparent",
      darkItemBg: "transparent",
    },
    Layout: {
      colorBgLayout: "unset",
      headerBg: "#021E6A",
      bodyBg: "#E5E5E5",
    },
    Switch: {
      colorPrimary: "#5AC7EA",
    },
    Checkbox: {
      colorPrimary: "#5AC7EA",
    },
  },
}

export default theme
