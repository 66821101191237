import { FC, SVGProps } from "react"
import { ReactComponent as Amazon } from "src/shared/icons/amazon.svg"
import { ReactComponent as BurgerMenu } from "src/shared/icons/burgerMenu.svg"
import { ReactComponent as CardFlag } from "src/shared/icons/cardFlag.svg"
import { ReactComponent as CaretDown } from "src/shared/icons/caretDown.svg"
import { ReactComponent as CaretSmall } from "src/shared/icons/caretSmall.svg"
import { ReactComponent as DepartmentDot } from "src/shared/icons/departmentDot.svg"
import { ReactComponent as Dot } from "src/shared/icons/dot.svg"
import { ReactComponent as ExtraBtn } from "src/shared/icons/extraBtn.svg"
import { ReactComponent as Inside } from "src/shared/icons/inside.svg"
import { ReactComponent as RowsTool } from "src/shared/icons/rowsTool.svg"
import { ReactComponent as Search } from "src/shared/icons/search.svg"
import { ReactComponent as StackTool } from "src/shared/icons/stackTool.svg"

export const icons = {
  burgerMenu: BurgerMenu,
  amazon: Amazon,
  search: Search,
  rowsTool: RowsTool,
  stackTool: StackTool,
  caretDown: CaretDown,
  extraBtn: ExtraBtn,
  dot: Dot,
  caretSmall: CaretSmall,
  inside: Inside,
  cardFlag: CardFlag,
  departmentDot: DepartmentDot,
} as const

export type IconsType = keyof typeof icons
type SvgIconProps = SVGProps<SVGSVGElement> & { type: IconsType }

export const SvgIcon: FC<SvgIconProps> = ({ type, ...svgProps }) => {
  const Icon = icons[type as IconsType] ?? null
  return Icon && <Icon {...svgProps} />
}
