import { Row } from "antd"
import { FC } from "react"
import { HeaderMenu } from "src/components/menu"
import { SvgIcon } from "src/shared/icons"
import "./Header.less"

const Header: FC<{ renderMenu?: FC }> = ({ renderMenu: HorizontalMenu = HeaderMenu }) => {
  return (
    <Row className={"header-container"} align={"middle"} justify={"start"}>
      <HorizontalMenu />
    </Row>
  )
}

export { Header }
