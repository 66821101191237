import { Layout as BaseLayout } from "antd"
import { FC, Suspense } from "react"
import { Outlet } from "react-router-dom"
import { Header } from "."
import { HeaderMenu } from "../menu"
import { FilterHeader } from "./Header/FilterHeader"

const Layout: FC = () => (
  <BaseLayout>
    <BaseLayout.Header>
      <Header renderMenu={HeaderMenu} />
    </BaseLayout.Header>
    <FilterHeader />
    <BaseLayout style={{ height: "100vh", padding: "20px 96px" }}>
      <Suspense>
        <Outlet />
      </Suspense>
    </BaseLayout>
  </BaseLayout>
)

export { Layout }
